.products {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;

    @media screen and (max-width: 720px) {
        justify-content: center;
        gap: 5px;
    }

    & .product {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;
        user-select: none;
        overflow: hidden;
        width: 375px !important;
        height: 550px !important;
        transition: all 0.3s ease-in-out;
        border-radius: 5px;
        background-color: white;
        z-index: 1;

        &--landing {
            width: 100% !important;
        }

        @media screen and (max-width: 720px) {
            width: 47.5% !important;
            height: 380px !important;
        }

        &:hover {
            z-index: 2;

            & .menu {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 1) 100%);
                background-color: rgba(255, 255, 255, 0.75);
                height: 120px;

                & .hide {
                    opacity: 1;
                }

                & .dots {
                    opacity: 1;
                }
            }
        }

        .menu {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background-color: white;
            backdrop-filter: blur(10px);
            transition: all 0.3s ease-in-out;

            .dots {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -125%);
                background-color: rgba(255, 255, 255, 0.75);
                border-radius: 10px;
                backdrop-filter: blur(10px);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 2px;
                padding: 5px;
                transition: all 0.3s ease-in-out;

                & .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #333;
                }
            }

            & .hide {
                opacity: 0;
            }

            & .colors {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 5px;

                & .active {
                    border: 2px solid black;
                    border-radius: 50%;

                    & .color {
                        margin: 2px;
                    }
                }

                & .color {
                    width: 24px !important;
                    height: 24px !important;
                    background-color: white;
                    border: 3px solid rgb(225, 225, 225);
                    border-radius: 50%;
                }

                & .red {
                    background-color: rgb(221, 42, 42);
                }

                & .blue {
                    background-color: rgb(59, 160, 255);
                }

                & .green {
                    background-color: rgb(59, 226, 59);
                }

                & .yellow {
                    background-color: rgb(255, 237, 78);
                }

                & .orange {
                    background-color: rgb(255, 166, 0);
                }

                & .purple {
                    background-color: rgb(153, 32, 153);
                }

                & .black {
                    background-color: black;
                }

                & .white {
                    background-color: white;
                }

                & .grey {
                    background-color: grey;
                }

                & .brown {
                    background-color: rgb(102, 37, 17);
                }

                & .pink {
                    background-color: rgb(255, 145, 163);
                }

                & .nude {
                    background-color: #E3BC9A;
                }
            }
        }


        & .images {
            position: absolute;
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            border-radius: 6px;

            @media screen and (max-width: 720px) {
                height: 380px;
                margin: 0 auto;
                flex-direction: column;
            }

            & .promo {
                position: absolute;
                top: 0;
                right: 0;
                padding: 5px 15px;
                width: auto;
                height: auto;
                z-index: 99999;
                background-color: #FFB6B6;
                color: white;
            }

            & .slick-arrow {
                z-index: 1;
                background-color: transparent !important;
                color: white !important;
                text-shadow: 0 0 5px black;
                width: 50px;
                height: 50px;
            }

            & .preview {
                position: relative;
                width: 100%;
                height: 500px;
                border-radius: 6px;
                transition: 0.5s ease-in-out;

                @media screen and (max-width: 720px) {
                    height: 380px;
                }

                & .slide {
                    position: relative;
                    width: 100%;
                    height: 500px;
                    object-fit: cover !important;

                    @media screen and (max-width: 720px) {
                        height: 380px;
                    }
                }
            }
        }

        .title {
            width: 100%;
            text-transform: uppercase;
            color: black;
            font-size: 18px;
            font-weight: 700 !important;
            text-align: start;

            @media screen and (max-width: 720px) {
                font-size: 11px !important;
            }
        }

        .description {
            color: #333;
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 500 !important;
            text-align: start;

            @media screen and (max-width: 720px) {
                font-size: 11px !important;
            }
        }

        .price {
            width: auto;
            text-align: start;

            @media screen and (max-width: 720px) {
                font-size: 10px !important;
            }
        }
    }
}

.pheader {
    position: relative;
    display: flex;
    margin: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 720px) {
        flex-direction: column;
    }
}

.filter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-shadow: none;
    gap: 10px;
    border-radius: 10px;
    width: 100%;
    text-align: start;

    @media screen and (max-width: 720px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    & select {
        cursor: pointer;
        margin: 5px;
        padding: 10px 15px !important;
        background-color: white;
        border-radius: 14px;
    }

    & .orderby {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & label {
            font-weight: bolder !important;
        }
    }

    & .order {
        width: 150px;

        @media screen and (max-width: 720px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            text-shadow: none;
            gap: 10px;
        }
    }

    & select {
        cursor: pointer;
        margin: 5px;
        padding: 5px 10px;
        width: 150px;
        border: none;
        font-weight: lighter;
        font-size: 12px;
        color: rgb(30, 30, 30)
    }

    & select:focus {
        outline: none;
    }
}