footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 1rem;

    @media only screen and (max-width: 1440px) {
        padding: 20px 3rem;
    }


    @media only screen and (max-width: 720px) {
        padding: 20px 1rem;
    }


    & .logo {
        height: 150px;
        width: auto;
        object-fit: cover;

        @media only screen and (max-width: 720px) {
            width: 100px;
            height: auto;
        }
    }
    
    & .choice {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #ddd;
        border: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & img {
            width: 50%;
            height: 50%;
            margin: auto;
            object-fit: cover;
        }
    }

    & hr {
        width: 75%;
        border: none;
        margin: 25px auto;

        @media only screen and (max-width: 720px) {
            width: 100%;
        }
    }

    & .color--black {
        font-size: 20px;
        font-weight: bold;
        color: #000;

        @media only screen and (max-width: 720px) {
            font-size: 16px;
        }
    }

    & .color--gray {
        text-decoration: none!important;
        color: #646464;
        cursor: pointer;

        @media only screen and (max-width: 720px) {
            font-size: 12px;
        }
    }

    & .copyright {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 50px;
        padding: 10px 100px;
        color: grey;

        @media only screen and (max-width: 720px) {
            flex-direction: column-reverse;
            gap: 30px;
            padding: 10px;
            font-size: 10px;
        }
    }
}