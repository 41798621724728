.landing__gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding: 0;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        gap: 0;
    }

    & .story {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        transition: 0.5s ease-in-out;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 25px 50px 30px;
            background: linear-gradient(180deg, rgba(155, 155, 155, 0.41) 0%, rgba(155, 155, 155, 0.00) 100%);
            border-radius: 20px;
            transition: 0.5s ease-in-out;
            z-index: 999999;

            @media screen and (max-width: 720px) {
                background-color: rgba(150, 150, 150, 0);
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
        }

        @media screen and (max-width: 720px) {
            width: 80%;
            height: 350px;
            margin: auto;
        }

        &:hover {
            transform: translate(0, -5%);

            & .story__content {
                transform: rotate(5deg);
            }
        }

        &__image {
            width: 100%;
            height: 500px;
            object-fit: cover;
            border-radius: 20px;
            z-index: 9999;

            @media screen and (max-width: 720px) {
                width: 100%;
                height: 100%;
                margin: 0 auto;
            }
        }

        &__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 25px 50px 30px;
            background-color: #000;
            border-radius: 20px;
            z-index: -1;
            transition: 0.5s ease-in-out;

            @media screen and (max-width: 720px) {
                width: 90%;
                height: 90%;
                margin: 0 auto;
            }
        }
    }
}