.page-header {
    padding: 0 16px;

    @media screen and (max-width: 720px) {
        padding: 30px 0;
    }

    & .title {
        font-size: 22px !important;

        @media screen and (max-width: 720px) {
            font-size: 18px !important;
        }
    }

    & .subtitle {
        font-size: 20px !important;

        @media screen and (max-width: 720px) {
            font-size: 16px !important;
        }
    }
}

.landing__slider {
    position: relative;
    width: 100%;
    height: calc(80vh - 70px) !important;
    z-index: 1;

    & .slider__arrows {
        position: absolute;
        bottom: 7.5%;
        right: 5%;
        z-index: 9999999;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 720px) {
            right: 10%;
        }

        & .arrow-btn {
            border-radius: 50%;
            background-color: rgb(0, 0, 0, 0.5);
            color: white;
            text-align: center;
            line-height: 14px;
            font-size: 14px;
            padding: 7.5px;
            cursor: pointer; //Nu uita de asta ca dupa se vede urat
        }
    }

    .slide {
        position: relative;
    }

    & .background {
        width: 100%;
        height: calc(80vh - 70px) !important;
        object-fit: cover;
    }

    & .admin {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        width: auto;
        min-height: 50px;
        z-index: 9999;
    }

    & .overlay {
        position: absolute;
        bottom: 80px;
        left: 80px;
        width: 50vw;
        min-height: 50px;
        z-index: 9999;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: 1024px) {
            width: 80vw;
            bottom: 80px;
            left: 16px;
        }

        & .title {
            color: white !important;
            margin: 0;

            @media screen and (max-width: 720px) {
                font-size: 32px !important;
            }
        }
        
        & .subtitle {
            color: white !important;
            margin: 0;
            @media screen and (max-width: 720px) {
                font-size: 20px !important;
            }
        }
    }
}

.image--size-landing {
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin: auto;

    @media screen and (max-width: 720px){
        width: 200px;
        height: 200px;
    }
}

.fitswell {
    width: 90% !important;
}

.category {
    position: relative;
    width: 100%;
    height: 550px;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;

    @media screen and (max-width: 720px) {
        width: 95%;
        height: 450px;
        margin: 0 auto;
    }

    &:hover {
        filter: grayscale(0%);
        transform: scale(1.05);
        z-index: 9999;
        box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.15);


        & .image {
            transform: scale(1.25);
        }
    }

    & .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s ease-in-out;
    }

    & .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 20px;
        align-items: center;
        text-align: center;
        padding: 0 0 20% 0;
        transition: 0.5s ease-in-out;

        & .title {
            color: white !important;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        }

        & .button {
            cursor: pointer;
            width: 100%;
            padding: 10px 0;
            background-color: transparent;
            font-size: 18px;
            color: white;
            padding: 10px 30px;
            border-radius: 0;
            border: 1px solid white;
            backdrop-filter: blur(5px);
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        }
    }
}

.landing__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0 auto;
    width: 100%;
    height: 60vh;
    z-index: 9999;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        min-height: 80vh;
        height: auto;
        margin: auto;

        &--reverse {
            flex-direction: column-reverse;
        }
    }

    & .left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border-radius: 15px 0 0 15px;

        @media screen and (max-width: 720px) {
            width: 100%;
            min-height: 50%;
            height: auto;
            border-radius: 15px 15px 0 0;

            &--reverse {
                border-radius: 0 0 15px 15px;
            }
        }
    }   

    & .right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        border-radius: 0 15px 15px 0;

        @media screen and (max-width: 720px) {
            width: 100%;
            min-height: 50%;
            height: auto;
            border-radius: 0 0 15px 15px;

            &--reverse {
                border-radius: 15px 15px 0 0;
            }
        }
    }

    & .image {
        width: 50%;
        height: 50%;
        object-fit: contain;
        margin: auto;

        @media screen and (max-width: 720px) {
            margin: 80px auto;            
        }
    }

    & .red {
        background-color: rgb(226, 88, 88);
    }

    & .blue {
        background-color: rgb(59, 160, 255);
    }

    & .green {
        background-color: rgb(59, 226, 59);
    }

    & .yellow {
        background-color: rgb(255, 237, 78);
    }

    & .orange {
        background-color: rgb(255, 166, 0);
    }

    & .purple {
        background-color: rgb(153, 32, 153);
    }

    & .black {
        background-color: black;
    }

    & .white {
        background-color: white;
    }

    & .grey {
        background-color: grey;
    }

    & .brown {
        background-color: rgb(102, 37, 17);
    }

    & .pink {
        background-color: #FFB6B6;
    }

    & .nude {
        background-color: #ffc3a8;
    }
}