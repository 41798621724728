.filters {
    transition: 0.5s ease-in-out;
    margin-right: 20px;
    color: black;

    @media screen and (max-width: 720px) {
        width: 100%;
        margin-right: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5px;
        font-weight: lighter;
        animation: init__filters__menu 0.5s ease-in-out forwards;

        @media screen and (max-width: 720px) {
            padding-bottom: 75px;
        }
    }

    &__title {
        position: relative;
        font-size: 32px;
        font-weight: 700;
        background-color: #222;
        color: #f9f9f9;
        padding: 5px 10px;
        border-radius: 5px;
        height: fit-content;
    }

    &__choice {
        user-select: none;

        &__content {
            animation: filters__choice__content 0.5s ease-in-out forwards;

            &--opened {
                animation: filters__choice__content 0.5s ease-in-out forwards;
            }

            &--closed {
                animation: filters__choice__hide 0.5s ease-in-out forwards;
            }
        }

        &__icon {
            &--open {
                animation: filters__choice__icon__open 0.5s ease-in-out forwards;
            }

            &--close {
                animation: filters__choice__icon__close 0.5s ease-in-out forwards;
            }
        }
    }


    &__close {
        cursor: pointer;
        user-select: none;
        transition: 0.5s ease-in-out;
        transform: translate(0, -10%);

        &:hover {
            transform: translate(0, -10%) rotate(360deg);
        }
    }
}

@keyframes init__filters__menu {
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: auto;
        opacity: 1;
    }
}

@keyframes filters__choice__title__open {
    from {
        width: 0;
    }

    to {
        width: 75%;
    }
}

@keyframes filters__choice__title__close {
    from {
        width: 75%;
    }

    to {
        width: 0;
    }
}

@keyframes filters__choice__content {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes filters__choice__hide {
    from {
        opacity: 1;
        height: auto;
    }

    to {
        opacity: 0;
        height: 0;
    }
}

@keyframes filters__choice__icon__open {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes filters__choice__icon__close {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}