.slider {
    height: 100% !important;

    & div {
        border: 0;
    }

    @media screen and (max-width: 720px) {
        height: auto;
        width: 100%;
    }

    & .slide {
        z-index: 0;
    }

    & .slick-arrow {
        z-index: 1;
        background-color: #000 !important;
        color: white !important;
        width: 50px;
        height: 50px;
    }

    & .slick-prev {
        position: absolute;
        bottom: 0;
        left: 5px !important;
        border-radius: 50%;

        &:before {
            font-family: "Quicksand", sans-serif !important;
            content: "\2190";
            border-radius: 100%;
        }
    }

    & .slick-next {
        position: absolute;
        top: 50%;
        right: 5px !important;
        border-radius: 50%;

        &:before {
            font-family: "Poppins", sans-serif !important;
            content: "\2192";
            border-radius: 100%;
        }
    }

    & .slick-dots {
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);

        & li {
            margin: 0 10px !important;

            & button {
                &:before {
                    content: none;
                }

                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(4px);
                cursor: pointer;
            }
        }

        & .slick-active {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

#price__filter {
    background-color: #bbb;
    height: 2px;
}

#price__filter .range-slider__range {
    background: #000;
    transition: height 0.3s;
}

#price__filter .range-slider__thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #333;
    transition: transform 0.3s;
}

#price__filter .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
}

#price__filter .range-slider__range[data-active] {
    height: 16px;
}