.checkout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
    &__item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid black;
        padding: 20px;
        font-size: 12px;
    }
}