header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 1rem;
    z-index: 9999999999 !important;
    width: 100%;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(20px);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 70px;
    }

    @media only screen and (max-width: 1440px) {
        padding: 20px 3rem;
        border-radius: 0px 0px 40px 40px;
    }


    @media only screen and (max-width: 720px) {
        padding: 20px 1rem;
        border-radius: 0px 0px 40px 40px;
    }

    & .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 60px;
        width: auto;
        object-fit: cover;
        transform: translate(-50%, -50%);
    }

    & .hamburger {
        font-size: 50px;
        transform: translateY(20%) scale(0.75);

        @media screen and (max-width: 720px) {
            transform: scale(0.75);
        }
    }

    & .nav {
        position: relative;
        width: max-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        gap: 20px;
        height: min-content;

        &__button {
            position: relative;
            outline: none;
            cursor: pointer;
            user-select: none;
            align-content: center;

            &__img {
                height: 32px;
                width: 32px;
                object-fit: cover;
            }

            &__count {
                font-family: 'Poppins', sans-serif;
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                transform: translate(50%, -25%);
                border-radius: 50%;
                background-color: #d61616;
                color: #fff;
                font-size: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }


        &__option {
            position: relative;
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            height: 15px;
            font-size: 15px !important;
            letter-spacing: 1px;
            color: #000;
            z-index: 9999999;
            font-weight: 300;
            transition: 0.5s ease-in-out;

            &--dark {
                background: #66C3D1;
                color: #fff;
                padding: 10px 10px;
                line-height: 2px;
                border-radius: 15px;

                &:hover {
                    transform: scale(1.1);
                    color: #fff;
                    text-decoration: none !important;
                }
            }

            & .arrow__down {
                border: solid black;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 3px;
                transform: rotate(45deg) translate(0, -70%);
            }

            & .nav__dropdown {
                display: none;
                position: absolute;
                flex-direction: column;
                justify-content: flex-start;
                top: 80%;
                left: 50%;
                width: 300px;
                transform: translate(-50%, 0);
                margin: 10px 0;
                gap: 10px;
                padding: 30px 50px;
                font-weight: 300;
                color: #222;
                text-wrap: nowrap;
                z-index: 9999999;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    background-color: rgba(255, 255, 255, 0.75);
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.75) 100%);
                    backdrop-filter: blur(20px);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                &:hover {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                }
            }

            &:hover {
                text-decoration: underline;

                & .nav__dropdown {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    &>a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.nav__menu {
    position: relative;
    transition: 0.5s ease-in-out;
    z-index: 9999999999;

    &__close-button {
        position: absolute;
        top: 24px;
        right: 12px;
        width: 48px;
        height: 48px;
        font-size: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        object-fit: cover;
        cursor: pointer;
        user-select: none;
        z-index: 9999999999;
        transition: 0.5s ease-in-out;
        color: white;
        border-radius: 50%;
        border: 1px solid white;

        &:hover {
            transform: rotate(360deg);
        }
    }

    &__logo {
        height: 32px;
        width: 32px;
        object-fit: cover;
        margin-right: 10px;
    }

    &__content {
        position: fixed;
        top: 0;
        left: 0;
        width: auto;
        min-width: 300px;
        height: 100vh;
        background-color: white;
        z-index: 999999;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        font-weight: lighter;
        animation: init__menu__content 0.5s ease-in-out forwards;
        overflow-y: auto;

        @media screen and (max-width: 720px) {
            width: 80%;
        }
    }

    &__button {
        height: 32px;
        width: 32px;
        object-fit: cover;

        & img {
            height: 32px;
            width: 32px;
            object-fit: cover;
        }

        &--cart {
            & img {
                height: 32px;
                width: 38px;
                object-fit: cover;
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        animation: init__menu__overlay 0.5s ease-in-out forwards;
    }

    &__title {
        position: relative;
        margin: 5px 20px;
        font-size: 24px;
        font-weight: 500;
        height: fit-content;

        &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 75%;
            height: 2px;
            background-color: #000;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        object-fit: cover;
        margin-right: 5px;
    }

    &__choice {
        color: #333;
        font-size: 14px;

        &__title {
            position: relative;
            font-size: 24px;
            font-weight: 500;
            height: fit-content;
            color: black;
        }

        &__content {
            animation: nav__menu__choice__content 0.5s ease-in-out forwards;
        }

        &__icon {
            &--open {
                animation: nav__menu__choice__icon__open 0.5s ease-in-out forwards;
            }

            &--close {
                animation: nav__menu__choice__icon__close 0.5s ease-in-out forwards;
            }
        }
    }

    &__close {
        cursor: pointer;
        user-select: none;
        transition: 0.5s ease-in-out;
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, 0);

        &:hover {
            transform: translate(-50%, 0) rotate(360deg);
        }
    }
}

.promo__banner {
    position: relative;
    width: 100%;
    padding: 5px 0;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 9999999999;
    background-color: #FFB6B6 !important;

    &__title {
        font-weight: 600;
        font-size: 18px;
        color: black !important;
        text-align: center;

        @media screen and (max-width: 720px) {
            font-size: 12px;
        }
    }
}

@keyframes init__menu__content {
    from {
        transform: translate(-100%);
    }

    to {
        transform: translate(0);
    }
}

@keyframes init__menu__overlay {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes nav__menu__choice__title__open {
    from {
        width: 0;
    }

    to {
        width: 75%;
    }
}

@keyframes nav__menu__choice__title__close {
    from {
        width: 75%;
    }

    to {
        width: 0;
    }
}

@keyframes nav__menu__choice__content {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes nav__menu__choice__icon__open {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes nav__menu__choice__icon__close {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}