@font-face {
    font-family: 'Droids Sans';
    src: url("../assets/fonts/Droids\ Sans\ Italic.otf") format('opentype');
}

.disclaimer {
    font-family: 'Droids Sans', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
    }

    & img {
        width: 300px;
        height: 150px;
        padding: 0 25px;
        margin: 20px 0;
        object-fit: contain;
        mix-blend-mode: multiply;
        border-radius: 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @media screen and (max-width: 768px) {
            width: 100%;
            
        }
    }

    & a {
        text-decoration: underline;
        color: #333;
    }
}