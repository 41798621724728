.reviews__list {
  position: relative;

  & .slick-arrow {
    padding: auto;
    margin: auto;
    z-index: 1;
    background-color: #000 !important;
    color: #000 !important;
    width: 50px;
    height: 50px;
  }

  & .slick-prev {
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    transform: translate(-100%, 0);

    @media screen and (max-width: 720px) {
      top: 95%;
      transform: translate(-35%, 0);
    }

    &:before {
      font-family: "Quicksand", sans-serif !important;
      content: "\2190";
      border-radius: 100%;
    }
  }

  & .slick-next {
    position: absolute;
    top: 50%;
    right: 0;
    border-radius: 50%;
    transform: translate(100%, 0);

    @media screen and (max-width: 720px) {
      top: 95%;
      transform: translate(35%, 0);
    }

    &:before {
      font-family: "Poppins", sans-serif !important;
      content: "\2192";
      border-radius: 100%;
    }
  }

  & .slick-track {
    display: flex !important
  }

  & .slick-slide {
    width: 100% !important;
    height: 100% !important;
  }

  & .review {
    position: relative;
    width: 75%;
    height: 300px;
    transition: 0.5s ease-in-out;
    border-radius: 10px;
    margin: 15px;

    @media screen and (max-width: 720px) {
      width: 95%;
      height: auto;
      margin: 0 auto;
    }

    & .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s ease-in-out;
    }

    & .logo {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin: 20px auto;
    }

    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-wrap: wrap;
      overflow-y: auto;

      & .choice {
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #ddd;
          border: 1px solid #ccc;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
  
          & img {
              width: 50%;
              height: 50%;
              margin: auto;
              object-fit: cover;
          }
      }
    }
  }
}