.wapp--button {
	position:fixed;
	width:90px;
	height:90px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50%;
	text-align:center;
    font-size: 60px;
    line-height: 90px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
    transition: 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out forwards;

    &:hover {
        transform: scale(1.05);
        color:#FFF;
    }

    @media only screen and (max-width: 720px) {
        bottom:10px;
        right:10px;
        width:60px;
        height:60px;
        font-size: 40px;
        line-height: 60px;
    }
}