.configurator {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    width: 100%;

    @media screen and (max-width: 720px) {
        flex-direction: column;
    }

    & .container {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px;
        margin: 0;
    }

    & .choices {
        width: 50%;

        @media screen and (max-width: 720px) {
            width: 100%;
        }
    }

    & .preview {
        position: relative;
        width: 512px;
        height: 512px;

        @media screen and (max-width: 720px) {
            width: 100%;
            height: 100%;
        }

        & .disclaimer {
            font-family: "Architects Daughter", sans-serif !important;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            font-size: 12px;
            text-align: center;

            @media screen and (max-width: 720px) {
                font-size: 8px;
                transform: translate(-50%, 50%);
            }
        }

        & .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        & .border {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 150px;
            height: 150px;
            object-fit: contain;
            border: 2px rgba(100, 100, 100, 0.5) dashed !important;

            &--pillow {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 300px;
                height: 300px;
                border: 2px rgba(100, 100, 100, 0.5) dashed !important;

                @media screen and (max-width: 720px) {
                    width: 200px;
                    height: 200px;
                }
            }

            &--pack {
                top: 67.5%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                border: 2px rgba(100, 100, 100, 0.5) dashed !important;
            }

            &--pack1 {
                top: 35%;
                left: 30%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                border: 2px rgba(100, 100, 100, 0.5) dashed !important;
            }

            &--pack2 {
                top: 35%;
                left: 74%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                border: 2px rgba(100, 100, 100, 0.5) dashed !important;
            }

            &--hoodie-back {
                top: 60%;
            }
        }

        & .client__img {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 150px;
            height: 150px;
            object-fit: contain;

            &--pillow {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 300px;
                height: 300px;

                @media screen and (max-width: 720px) {
                    width: 200px;
                    height: 200px;
                }
            }

            &--pack {
                top: 67.5%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
            }

            &--pack1 {
                top: 35%;
                left: 30%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
            }

            &--pack2 {
                top: 35%;
                left: 74%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
            }

            &--hoodie-back {
                top: 30%;
            }
        }
    }

    & .add__image {
        width: 256px;
        height: 256px;
        object-fit: contain;
        cursor: pointer;

        @media screen and (max-width: 720px) {
            // width: 128px;
            // height: 128px;
        }
    }

    & .size {
        // border: 3px solid black !important;
        color: black !important;
        background-color: whitesmoke;
    }

    .white {
        background-color: #fff !important;
    }

    .black {
        background-color: #000 !important;
    }

    .darkgray {
        background-color: #323638 !important;
    }

    .marineblue {
        background-color: #192a35 !important;
    }

    .lightgray {
        background-color: lightgray !important;
    }

    .gray {
        background-color: #7a757b !important;
    }

    .lightcream {
        background-color: #c6bbaf !important;
    }

    .lightblue {
        background-color: lightblue !important;
    }

    .darkgreen {
        background-color: darkgreen !important;
    }

    .cream {
        background-color: #c6bbaf !important;
    }

    .darkblue {
        background-color: #1e2e3c !important;
    }

    .blue {
        background-color: #516d86 !important;
    }

    .pink {
        background-color: rgb(248, 101, 126) !important;
    }

    .lilapastel {
        background-color: #BCAFCF !important;
    }

    .blue-gray {
        background: linear-gradient(90deg, rgba(158,158,158,1) 0%, rgba(158,158,158,1) 50%, rgba(81,109,134,1) 50%, rgba(81,109,134,1) 100%);
    }

    .pink-gray {
        background: linear-gradient(90deg, rgba(158,158,158,1) 0%, rgba(158,158,158,1) 50%, rgba(248,101,126,1) 50%, rgba(248,101,126,1) 100%);
    }

    .pink-cream {
        background: linear-gradient(90deg, rgba(198,187,175,1) 0%, rgba(198,187,175,1) 50%, rgba(248,101,126,1) 50%, rgba(248,101,126,1) 100%);
    }

    .blue-cream {
        background: linear-gradient(90deg, rgba(198,187,175,1) 0%, rgba(198,187,175,1) 50%, rgba(81,109,134,1) 50%, rgba(81,109,134,1) 100%);
    }

    & .selected {
        background-color: white !important;
        border: 2px solid black !important;
        border-radius: 50%;
    }

    & .button {
        background-color: whitesmoke;
        border: 1px solid lightgray;
        color: black;

        &:hover {
            background-color: #FF006B;
            color: white;
        }
    }

    & .button--selected {
        background-color: #FF006B !important;
        color: white !important;
    }
}


.configurator__price {
    color: white !important;
    font-family: "Luckiest Guy" !important;
    font-size: 24px !important;
    letter-spacing: 2px;
    user-select: none;
    cursor: default !important;
}