@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 1rem;
  z-index: 9999999999 !important;
  width: 100%;
}
header:before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
}
@media only screen and (max-width: 1440px) {
  header {
    padding: 20px 3rem;
    border-radius: 0px 0px 40px 40px;
  }
}
@media only screen and (max-width: 720px) {
  header {
    padding: 20px 1rem;
    border-radius: 0px 0px 40px 40px;
  }
}
header .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(-50%, -50%);
}
header .hamburger {
  font-size: 50px;
  transform: translateY(20%) scale(0.75);
}
@media screen and (max-width: 720px) {
  header .hamburger {
    transform: scale(0.75);
  }
}
header .nav {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 20px;
  height: -moz-min-content;
  height: min-content;
}
header .nav__button {
  position: relative;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-content: center;
}
header .nav__button__img {
  height: 32px;
  width: 32px;
  -o-object-fit: cover;
     object-fit: cover;
}
header .nav__button__count {
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translate(50%, -25%);
  border-radius: 50%;
  background-color: #d61616;
  color: #fff;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .nav__option {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: 500;
  height: 15px;
  font-size: 15px !important;
  letter-spacing: 1px;
  color: #000;
  z-index: 9999999;
  font-weight: 300;
  transition: 0.5s ease-in-out;
}
header .nav__option--dark {
  background: #66C3D1;
  color: #fff;
  padding: 10px 10px;
  line-height: 2px;
  border-radius: 15px;
}
header .nav__option--dark:hover {
  transform: scale(1.1);
  color: #fff;
  text-decoration: none !important;
}
header .nav__option .arrow__down {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translate(0, -70%);
}
header .nav__option .nav__dropdown {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  top: 80%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, 0);
  margin: 10px 0;
  gap: 10px;
  padding: 30px 50px;
  font-weight: 300;
  color: #222;
  text-wrap: nowrap;
  z-index: 9999999;
}
header .nav__option .nav__dropdown:before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.75);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.75) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
header .nav__option .nav__dropdown:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
header .nav__option:hover {
  text-decoration: underline;
}
header .nav__option:hover .nav__dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
header .nav__option:hover .nav__dropdown > a:hover {
  text-decoration: underline;
}

.nav__menu {
  position: relative;
  transition: 0.5s ease-in-out;
  z-index: 9999999999;
}
.nav__menu__close-button {
  position: absolute;
  top: 24px;
  right: 12px;
  width: 48px;
  height: 48px;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 9999999999;
  transition: 0.5s ease-in-out;
  color: white;
  border-radius: 50%;
  border: 1px solid white;
}
.nav__menu__close-button:hover {
  transform: rotate(360deg);
}
.nav__menu__logo {
  height: 32px;
  width: 32px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}
.nav__menu__content {
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  min-width: 300px;
  height: 100vh;
  background-color: white;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  font-weight: lighter;
  animation: init__menu__content 0.5s ease-in-out forwards;
  overflow-y: auto;
}
@media screen and (max-width: 720px) {
  .nav__menu__content {
    width: 80%;
  }
}
.nav__menu__button {
  height: 32px;
  width: 32px;
  -o-object-fit: cover;
     object-fit: cover;
}
.nav__menu__button img {
  height: 32px;
  width: 32px;
  -o-object-fit: cover;
     object-fit: cover;
}
.nav__menu__button--cart img {
  height: 32px;
  width: 38px;
  -o-object-fit: cover;
     object-fit: cover;
}
.nav__menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: init__menu__overlay 0.5s ease-in-out forwards;
}
.nav__menu__title {
  position: relative;
  margin: 5px 20px;
  font-size: 24px;
  font-weight: 500;
  height: -moz-fit-content;
  height: fit-content;
}
.nav__menu__title::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 75%;
  height: 2px;
  background-color: #000;
}
.nav__menu__icon {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 5px;
}
.nav__menu__choice {
  color: #333;
  font-size: 14px;
}
.nav__menu__choice__title {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  height: -moz-fit-content;
  height: fit-content;
  color: black;
}
.nav__menu__choice__content {
  animation: nav__menu__choice__content 0.5s ease-in-out forwards;
}
.nav__menu__choice__icon--open {
  animation: nav__menu__choice__icon__open 0.5s ease-in-out forwards;
}
.nav__menu__choice__icon--close {
  animation: nav__menu__choice__icon__close 0.5s ease-in-out forwards;
}
.nav__menu__close {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: 0.5s ease-in-out;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}
.nav__menu__close:hover {
  transform: translate(-50%, 0) rotate(360deg);
}

.promo__banner {
  position: relative;
  width: 100%;
  padding: 5px 0;
  -o-object-fit: cover;
     object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 9999999999;
  background-color: #FFB6B6 !important;
}
.promo__banner__title {
  font-weight: 600;
  font-size: 18px;
  color: black !important;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .promo__banner__title {
    font-size: 12px;
  }
}

@keyframes init__menu__content {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes init__menu__overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes nav__menu__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@keyframes nav__menu__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@keyframes nav__menu__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes nav__menu__choice__icon__open {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes nav__menu__choice__icon__close {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.slider {
  height: 100% !important;
}
.slider div {
  border: 0;
}
@media screen and (max-width: 720px) {
  .slider {
    height: auto;
    width: 100%;
  }
}
.slider .slide {
  z-index: 0;
}
.slider .slick-arrow {
  z-index: 1;
  background-color: #000 !important;
  color: white !important;
  width: 50px;
  height: 50px;
}
.slider .slick-prev {
  position: absolute;
  bottom: 0;
  left: 5px !important;
  border-radius: 50%;
}
.slider .slick-prev:before {
  font-family: "Quicksand", sans-serif !important;
  content: "←";
  border-radius: 100%;
}
.slider .slick-next {
  position: absolute;
  top: 50%;
  right: 5px !important;
  border-radius: 50%;
}
.slider .slick-next:before {
  font-family: "Poppins", sans-serif !important;
  content: "→";
  border-radius: 100%;
}
.slider .slick-dots {
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
}
.slider .slick-dots li {
  margin: 0 10px !important;
}
.slider .slick-dots li button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  cursor: pointer;
}
.slider .slick-dots li button:before {
  content: none;
}
.slider .slick-dots .slick-active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

#price__filter {
  background-color: #bbb;
  height: 2px;
}

#price__filter .range-slider__range {
  background: #000;
  transition: height 0.3s;
}

#price__filter .range-slider__thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #333;
  transition: transform 0.3s;
}

#price__filter .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#price__filter .range-slider__range[data-active] {
  height: 16px;
}

.cart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.cart__product {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .cart__product {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.cart__product__preview {
  position: relative;
}
.cart__product__image {
  width: 80px;
  height: 100px;
  border-radius: 10px;
  -o-object-fit: contain;
     object-fit: contain;
}
.cart__product__climage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
}
.cart__product__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  padding: 0 5px;
}

.checkout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.checkout__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid black;
  padding: 20px;
  font-size: 12px;
}

.configurator {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
@media screen and (max-width: 720px) {
  .configurator {
    flex-direction: column;
  }
}
.configurator .container {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  margin: 0;
}
.configurator .choices {
  width: 50%;
}
@media screen and (max-width: 720px) {
  .configurator .choices {
    width: 100%;
  }
}
.configurator .preview {
  position: relative;
  width: 512px;
  height: 512px;
}
@media screen and (max-width: 720px) {
  .configurator .preview {
    width: 100%;
    height: 100%;
  }
}
.configurator .preview .disclaimer {
  font-family: "Architects Daughter", sans-serif !important;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .configurator .preview .disclaimer {
    font-size: 8px;
    transform: translate(-50%, 50%);
  }
}
.configurator .preview .image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.configurator .preview .border {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 150px;
  height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pillow {
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
@media screen and (max-width: 720px) {
  .configurator .preview .border--pillow {
    width: 200px;
    height: 200px;
  }
}
.configurator .preview .border--pack {
  top: 67.5%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pack1 {
  top: 35%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--pack2 {
  top: 35%;
  left: 74%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px rgba(100, 100, 100, 0.5) dashed !important;
}
.configurator .preview .border--hoodie-back {
  top: 60%;
}
.configurator .preview .client__img {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 150px;
  height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
.configurator .preview .client__img--pillow {
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
}
@media screen and (max-width: 720px) {
  .configurator .preview .client__img--pillow {
    width: 200px;
    height: 200px;
  }
}
.configurator .preview .client__img--pack {
  top: 67.5%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--pack1 {
  top: 35%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--pack2 {
  top: 35%;
  left: 74%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.configurator .preview .client__img--hoodie-back {
  top: 30%;
}
.configurator .add__image {
  width: 256px;
  height: 256px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
}
.configurator .size {
  color: black !important;
  background-color: whitesmoke;
}
.configurator .white {
  background-color: #fff !important;
}
.configurator .black {
  background-color: #000 !important;
}
.configurator .darkgray {
  background-color: #323638 !important;
}
.configurator .marineblue {
  background-color: #192a35 !important;
}
.configurator .lightgray {
  background-color: lightgray !important;
}
.configurator .gray {
  background-color: #7a757b !important;
}
.configurator .lightcream {
  background-color: #c6bbaf !important;
}
.configurator .lightblue {
  background-color: lightblue !important;
}
.configurator .darkgreen {
  background-color: darkgreen !important;
}
.configurator .cream {
  background-color: #c6bbaf !important;
}
.configurator .darkblue {
  background-color: #1e2e3c !important;
}
.configurator .blue {
  background-color: #516d86 !important;
}
.configurator .pink {
  background-color: rgb(248, 101, 126) !important;
}
.configurator .lilapastel {
  background-color: #BCAFCF !important;
}
.configurator .blue-gray {
  background: linear-gradient(90deg, rgb(158, 158, 158) 0%, rgb(158, 158, 158) 50%, rgb(81, 109, 134) 50%, rgb(81, 109, 134) 100%);
}
.configurator .pink-gray {
  background: linear-gradient(90deg, rgb(158, 158, 158) 0%, rgb(158, 158, 158) 50%, rgb(248, 101, 126) 50%, rgb(248, 101, 126) 100%);
}
.configurator .pink-cream {
  background: linear-gradient(90deg, rgb(198, 187, 175) 0%, rgb(198, 187, 175) 50%, rgb(248, 101, 126) 50%, rgb(248, 101, 126) 100%);
}
.configurator .blue-cream {
  background: linear-gradient(90deg, rgb(198, 187, 175) 0%, rgb(198, 187, 175) 50%, rgb(81, 109, 134) 50%, rgb(81, 109, 134) 100%);
}
.configurator .selected {
  background-color: white !important;
  border: 2px solid black !important;
  border-radius: 50%;
}
.configurator .button {
  background-color: whitesmoke;
  border: 1px solid lightgray;
  color: black;
}
.configurator .button:hover {
  background-color: #FF006B;
  color: white;
}
.configurator .button--selected {
  background-color: #FF006B !important;
  color: white !important;
}

.configurator__price {
  color: white !important;
  font-family: "Luckiest Guy" !important;
  font-size: 24px !important;
  letter-spacing: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: default !important;
}

.loader__parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: show 1s forwards ease-in-out;
}
.loader__parent .logo {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  animation: rotate 3s forwards linear;
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  50% {
    transform: scale(1.1);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.landing__gallery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
}
@media screen and (max-width: 720px) {
  .landing__gallery {
    flex-direction: column;
    gap: 0;
  }
}
.landing__gallery .story {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  transition: 0.5s ease-in-out;
}
.landing__gallery .story::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px 50px 30px;
  background: linear-gradient(180deg, rgba(155, 155, 155, 0.41) 0%, rgba(155, 155, 155, 0) 100%);
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  z-index: 999999;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story::after {
    background-color: rgba(150, 150, 150, 0);
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 720px) {
  .landing__gallery .story {
    width: 80%;
    height: 350px;
    margin: auto;
  }
}
.landing__gallery .story:hover {
  transform: translate(0, -5%);
}
.landing__gallery .story:hover .story__content {
  transform: rotate(5deg);
}
.landing__gallery .story__image {
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
  z-index: 9999;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story__image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}
.landing__gallery .story__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px 50px 30px;
  background-color: #000;
  border-radius: 20px;
  z-index: -1;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .landing__gallery .story__content {
    width: 90%;
    height: 90%;
    margin: 0 auto;
  }
}

.page-header {
  padding: 0 16px;
}
@media screen and (max-width: 720px) {
  .page-header {
    padding: 30px 0;
  }
}
.page-header .title {
  font-size: 22px !important;
}
@media screen and (max-width: 720px) {
  .page-header .title {
    font-size: 18px !important;
  }
}
.page-header .subtitle {
  font-size: 20px !important;
}
@media screen and (max-width: 720px) {
  .page-header .subtitle {
    font-size: 16px !important;
  }
}

.landing__slider {
  position: relative;
  width: 100%;
  height: calc(80vh - 70px) !important;
  z-index: 1;
}
.landing__slider .slider__arrows {
  position: absolute;
  bottom: 7.5%;
  right: 5%;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 720px) {
  .landing__slider .slider__arrows {
    right: 10%;
  }
}
.landing__slider .slider__arrows .arrow-btn {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  line-height: 14px;
  font-size: 14px;
  padding: 7.5px;
  cursor: pointer;
}
.landing__slider .slide {
  position: relative;
}
.landing__slider .background {
  width: 100%;
  height: calc(80vh - 70px) !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.landing__slider .admin {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
  min-height: 50px;
  z-index: 9999;
}
.landing__slider .overlay {
  position: absolute;
  bottom: 80px;
  left: 80px;
  width: 50vw;
  min-height: 50px;
  z-index: 9999;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1024px) {
  .landing__slider .overlay {
    width: 80vw;
    bottom: 80px;
    left: 16px;
  }
}
.landing__slider .overlay .title {
  color: white !important;
  margin: 0;
}
@media screen and (max-width: 720px) {
  .landing__slider .overlay .title {
    font-size: 32px !important;
  }
}
.landing__slider .overlay .subtitle {
  color: white !important;
  margin: 0;
}
@media screen and (max-width: 720px) {
  .landing__slider .overlay .subtitle {
    font-size: 20px !important;
  }
}

.image--size-landing {
  width: 300px;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
}
@media screen and (max-width: 720px) {
  .image--size-landing {
    width: 200px;
    height: 200px;
  }
}

.fitswell {
  width: 90% !important;
}

.category {
  position: relative;
  width: 100%;
  height: 550px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  border-radius: 10px;
}
@media screen and (max-width: 720px) {
  .category {
    width: 95%;
    height: 450px;
    margin: 0 auto;
  }
}
.category:hover {
  filter: grayscale(0%);
  transform: scale(1.05);
  z-index: 9999;
  box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.15);
}
.category:hover .image {
  transform: scale(1.25);
}
.category .image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s ease-in-out;
}
.category .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
  text-align: center;
  padding: 0 0 20% 0;
  transition: 0.5s ease-in-out;
}
.category .overlay .title {
  color: white !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
.category .overlay .button {
  cursor: pointer;
  width: 100%;
  padding: 10px 0;
  background-color: transparent;
  font-size: 18px;
  color: white;
  padding: 10px 30px;
  border-radius: 0;
  border: 1px solid white;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.landing__container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  margin: 0 auto;
  width: 100%;
  height: 60vh;
  z-index: 9999;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
@media screen and (max-width: 720px) {
  .landing__container {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    min-height: 80vh;
    height: auto;
    margin: auto;
  }
  .landing__container--reverse {
    flex-direction: column-reverse;
  }
}
.landing__container .left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 15px 0 0 15px;
}
@media screen and (max-width: 720px) {
  .landing__container .left {
    width: 100%;
    min-height: 50%;
    height: auto;
    border-radius: 15px 15px 0 0;
  }
  .landing__container .left--reverse {
    border-radius: 0 0 15px 15px;
  }
}
.landing__container .right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border-radius: 0 15px 15px 0;
}
@media screen and (max-width: 720px) {
  .landing__container .right {
    width: 100%;
    min-height: 50%;
    height: auto;
    border-radius: 0 0 15px 15px;
  }
  .landing__container .right--reverse {
    border-radius: 15px 15px 0 0;
  }
}
.landing__container .image {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
  margin: auto;
}
@media screen and (max-width: 720px) {
  .landing__container .image {
    margin: 80px auto;
  }
}
.landing__container .red {
  background-color: rgb(226, 88, 88);
}
.landing__container .blue {
  background-color: rgb(59, 160, 255);
}
.landing__container .green {
  background-color: rgb(59, 226, 59);
}
.landing__container .yellow {
  background-color: rgb(255, 237, 78);
}
.landing__container .orange {
  background-color: rgb(255, 166, 0);
}
.landing__container .purple {
  background-color: rgb(153, 32, 153);
}
.landing__container .black {
  background-color: black;
}
.landing__container .white {
  background-color: white;
}
.landing__container .grey {
  background-color: grey;
}
.landing__container .brown {
  background-color: rgb(102, 37, 17);
}
.landing__container .pink {
  background-color: #FFB6B6;
}
.landing__container .nude {
  background-color: #ffc3a8;
}

.products {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@media screen and (max-width: 720px) {
  .products {
    justify-content: center;
    gap: 5px;
  }
}
.products .product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow: hidden;
  width: 375px !important;
  height: 550px !important;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  background-color: white;
  z-index: 1;
}
.products .product--landing {
  width: 100% !important;
}
@media screen and (max-width: 720px) {
  .products .product {
    width: 47.5% !important;
    height: 380px !important;
  }
}
.products .product:hover {
  z-index: 2;
}
.products .product:hover .menu {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgb(255, 255, 255) 100%);
  background-color: rgba(255, 255, 255, 0.75);
  height: 120px;
}
.products .product:hover .menu .hide {
  opacity: 1;
}
.products .product:hover .menu .dots {
  opacity: 1;
}
.products .product .menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: all 0.3s ease-in-out;
}
.products .product .menu .dots {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -125%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
.products .product .menu .dots .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
}
.products .product .menu .hide {
  opacity: 0;
}
.products .product .menu .colors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}
.products .product .menu .colors .active {
  border: 2px solid black;
  border-radius: 50%;
}
.products .product .menu .colors .active .color {
  margin: 2px;
}
.products .product .menu .colors .color {
  width: 24px !important;
  height: 24px !important;
  background-color: white;
  border: 3px solid rgb(225, 225, 225);
  border-radius: 50%;
}
.products .product .menu .colors .red {
  background-color: rgb(221, 42, 42);
}
.products .product .menu .colors .blue {
  background-color: rgb(59, 160, 255);
}
.products .product .menu .colors .green {
  background-color: rgb(59, 226, 59);
}
.products .product .menu .colors .yellow {
  background-color: rgb(255, 237, 78);
}
.products .product .menu .colors .orange {
  background-color: rgb(255, 166, 0);
}
.products .product .menu .colors .purple {
  background-color: rgb(153, 32, 153);
}
.products .product .menu .colors .black {
  background-color: black;
}
.products .product .menu .colors .white {
  background-color: white;
}
.products .product .menu .colors .grey {
  background-color: grey;
}
.products .product .menu .colors .brown {
  background-color: rgb(102, 37, 17);
}
.products .product .menu .colors .pink {
  background-color: rgb(255, 145, 163);
}
.products .product .menu .colors .nude {
  background-color: #E3BC9A;
}
.products .product .images {
  position: absolute;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 6px;
}
@media screen and (max-width: 720px) {
  .products .product .images {
    height: 380px;
    margin: 0 auto;
    flex-direction: column;
  }
}
.products .product .images .promo {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px;
  width: auto;
  height: auto;
  z-index: 99999;
  background-color: #FFB6B6;
  color: white;
}
.products .product .images .slick-arrow {
  z-index: 1;
  background-color: transparent !important;
  color: white !important;
  text-shadow: 0 0 5px black;
  width: 50px;
  height: 50px;
}
.products .product .images .preview {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 6px;
  transition: 0.5s ease-in-out;
}
@media screen and (max-width: 720px) {
  .products .product .images .preview {
    height: 380px;
  }
}
.products .product .images .preview .slide {
  position: relative;
  width: 100%;
  height: 500px;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
@media screen and (max-width: 720px) {
  .products .product .images .preview .slide {
    height: 380px;
  }
}
.products .product .title {
  width: 100%;
  text-transform: uppercase;
  color: black;
  font-size: 18px;
  font-weight: 700 !important;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .products .product .title {
    font-size: 11px !important;
  }
}
.products .product .description {
  color: #333;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500 !important;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .products .product .description {
    font-size: 11px !important;
  }
}
.products .product .price {
  width: auto;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .products .product .price {
    font-size: 10px !important;
  }
}

.pheader {
  position: relative;
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 720px) {
  .pheader {
    flex-direction: column;
  }
}

.filter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-shadow: none;
  gap: 10px;
  border-radius: 10px;
  width: 100%;
  text-align: start;
}
@media screen and (max-width: 720px) {
  .filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.filter select {
  cursor: pointer;
  margin: 5px;
  padding: 10px 15px !important;
  background-color: white;
  border-radius: 14px;
}
.filter .orderby {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.filter .orderby label {
  font-weight: bolder !important;
}
.filter .order {
  width: 150px;
}
@media screen and (max-width: 720px) {
  .filter .order {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    text-shadow: none;
    gap: 10px;
  }
}
.filter select {
  cursor: pointer;
  margin: 5px;
  padding: 5px 10px;
  width: 150px;
  border: none;
  font-weight: lighter;
  font-size: 12px;
  color: rgb(30, 30, 30);
}
.filter select:focus {
  outline: none;
}

.product__container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 720px) {
  .product__container {
    align-items: center;
    flex-direction: column;
  }
}
.product__container .bg-sec {
  background-color: #FF006B;
}
.product__container .images {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
@media screen and (max-width: 720px) {
  .product__container .images {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
  }
}
.product__container .images .preview {
  position: relative;
  width: 35vw;
  height: auto;
}
@media screen and (max-width: 720px) {
  .product__container .images .preview {
    width: 90vw !important;
    height: 60vh !important;
  }
}
.product__container .images .preview .slide {
  position: relative;
  width: 35vw;
  height: auto;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
@media screen and (max-width: 720px) {
  .product__container .images .preview .slide {
    width: 90vw !important;
    height: 60vh !important;
  }
}
.product__container .images .image {
  position: relative;
  width: 100px;
  height: 100px;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}
.product__container .title {
  color: black !important;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
}
.product__container .price {
  color: #FF006B !important;
  font-weight: 700;
  font-size: 34px;
}
.product__container .label {
  font-weight: bold;
  font-size: 16px;
  color: #B9BBBF;
  text-transform: uppercase;
}
.product__container .button {
  background-color: #000;
  width: 100%;
  border-radius: 35px;
}
.product__container .button--buy {
  background-color: #FF006B;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 65px;
  width: 50%;
  font-weight: 600;
}
.product__container .button--buy:hover {
  background-color: #FF006B !important;
}
@media screen and (max-width: 720px) {
  .product__container .button {
    margin: 0 0 !important;
    width: 100% !important;
    padding: 8px;
  }
}
.product__container .button:hover {
  background-color: #333;
}
.product__container .button--reverse {
  width: 50%;
  height: 65px;
  margin-left: 50px;
  background-color: #fff;
  color: #FF006B;
  border: 1px solid #FF006B;
  border-radius: 35px;
  padding: 8px;
  transition: 0.5s ease-in-out;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.product__container .button--reverse img {
  width: 24px;
  height: 24px;
  -o-object-fit: contain;
     object-fit: contain;
  filter: invert(17%) sepia(91%) saturate(6208%) hue-rotate(329deg) brightness(104%) contrast(109%);
}
.product__container .button--reverse:hover {
  transform: scale(1.1);
  border: 1px solid 333;
}
@media screen and (max-width: 720px) {
  .product__container .button--reverse:hover {
    transform: scale(1.01);
  }
}
@media screen and (max-width: 720px) {
  .product__container .button--reverse {
    width: 100%;
    margin-left: 0px;
  }
}
.product__container .button--rev-active {
  width: 50%;
  height: 65px;
  margin-left: 50px;
  background-color: #FF006B;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 35px;
  padding: 8px;
  transition: 0.5s ease-in-out;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.product__container .button--rev-active img {
  width: 24px;
  height: 24px;
  -o-object-fit: contain;
     object-fit: contain;
  filter: invert(1);
}
.product__container .button--rev-active:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 720px) {
  .product__container .button--rev-active:hover {
    transform: scale(1.01);
  }
}
.product__container .button--rev-active:hover img {
  filter: invert(1);
}
@media screen and (max-width: 720px) {
  .product__container .button--rev-active {
    width: 100%;
    margin-left: 0px;
  }
}
.product__container .quantity {
  height: 65px;
  border-radius: 35px;
  padding: 4px 15px;
  font-size: 20px;
  color: #FF006B;
  border: 1px solid #FF006B;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media screen and (max-width: 720px) {
  .product__container .quantity {
    padding: 4px 10px;
    font-size: 12px;
  }
}
.product__container .quantity .button {
  border: none;
  background-color: transparent;
  font-size: 32px;
  line-height: 20px;
  cursor: pointer;
  color: #FF006B;
  padding: 0;
  outline: none;
}
@media screen and (max-width: 720px) {
  .product__container .quantity .button {
    font-size: 12px;
    line-height: 10px;
    padding: 0 !important;
  }
}
.product__container .sizes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.product__container .sizes .active {
  background-color: #333 !important;
  color: white;
}
.product__container .sizes .size {
  min-width: 40px !important;
  height: 40px !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #999;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  margin: 4px;
  padding: 10px;
}
.product__container .sizes .size:hover {
  border: 1px solid #000;
}
.product__container .sizes .size .value {
  align-items: center;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  margin: auto;
}
.product__container .colors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.product__container .colors .active {
  border: 2px solid black;
  border-radius: 50%;
}
.product__container .colors .active .color {
  margin: 2px;
}
.product__container .colors .color {
  width: 40px !important;
  height: 40px !important;
  background-color: white;
  border: 1px solid #999;
  border-radius: 50%;
  margin: 4px;
}
.product__container .colors .red {
  background-color: rgb(221, 42, 42);
}
.product__container .colors .blue {
  background-color: rgb(59, 160, 255);
}
.product__container .colors .green {
  background-color: rgb(59, 226, 59);
}
.product__container .colors .yellow {
  background-color: rgb(255, 237, 78);
}
.product__container .colors .orange {
  background-color: rgb(255, 166, 0);
}
.product__container .colors .purple {
  background-color: rgb(153, 32, 153);
}
.product__container .colors .black {
  background-color: black;
}
.product__container .colors .white {
  background-color: white;
}
.product__container .colors .grey {
  background-color: grey;
}
.product__container .colors .brown {
  background-color: rgb(102, 37, 17);
}
.product__container .colors .pink {
  background-color: rgb(255, 145, 163);
}
.product__container .colors .nude {
  background-color: #E3BC9A;
}

@keyframes button__buy {
  0% {
    transform: translate(-2px, 1px);
  }
  25% {
    transform: translate(-2px, 0px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(0px, 1px);
  }
  100% {
    transform: translate(2px, 1px);
  }
}
.dropdown_card {
  background-color: #FAFAFA;
  color: #000;
}

footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 1rem;
}
@media only screen and (max-width: 1440px) {
  footer {
    padding: 20px 3rem;
  }
}
@media only screen and (max-width: 720px) {
  footer {
    padding: 20px 1rem;
  }
}
footer .logo {
  height: 150px;
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
@media only screen and (max-width: 720px) {
  footer .logo {
    width: 100px;
    height: auto;
  }
}
footer .choice {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
footer .choice img {
  width: 50%;
  height: 50%;
  margin: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
footer hr {
  width: 75%;
  border: none;
  margin: 25px auto;
}
@media only screen and (max-width: 720px) {
  footer hr {
    width: 100%;
  }
}
footer .color--black {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
@media only screen and (max-width: 720px) {
  footer .color--black {
    font-size: 16px;
  }
}
footer .color--gray {
  text-decoration: none !important;
  color: #646464;
  cursor: pointer;
}
@media only screen and (max-width: 720px) {
  footer .color--gray {
    font-size: 12px;
  }
}
footer .copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  padding: 10px 100px;
  color: grey;
}
@media only screen and (max-width: 720px) {
  footer .copyright {
    flex-direction: column-reverse;
    gap: 30px;
    padding: 10px;
    font-size: 10px;
  }
}

.filters {
  transition: 0.5s ease-in-out;
  margin-right: 20px;
  color: black;
}
@media screen and (max-width: 720px) {
  .filters {
    width: 100%;
    margin-right: 0;
  }
}
.filters__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  font-weight: lighter;
  animation: init__filters__menu 0.5s ease-in-out forwards;
}
@media screen and (max-width: 720px) {
  .filters__content {
    padding-bottom: 75px;
  }
}
.filters__title {
  position: relative;
  font-size: 32px;
  font-weight: 700;
  background-color: #222;
  color: #f9f9f9;
  padding: 5px 10px;
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
}
.filters__choice {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.filters__choice__content {
  animation: filters__choice__content 0.5s ease-in-out forwards;
}
.filters__choice__content--opened {
  animation: filters__choice__content 0.5s ease-in-out forwards;
}
.filters__choice__content--closed {
  animation: filters__choice__hide 0.5s ease-in-out forwards;
}
.filters__choice__icon--open {
  animation: filters__choice__icon__open 0.5s ease-in-out forwards;
}
.filters__choice__icon--close {
  animation: filters__choice__icon__close 0.5s ease-in-out forwards;
}
.filters__close {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: 0.5s ease-in-out;
  transform: translate(0, -10%);
}
.filters__close:hover {
  transform: translate(0, -10%) rotate(360deg);
}

@keyframes init__filters__menu {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: auto;
    opacity: 1;
  }
}
@keyframes filters__choice__title__open {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}
@keyframes filters__choice__title__close {
  from {
    width: 75%;
  }
  to {
    width: 0;
  }
}
@keyframes filters__choice__content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes filters__choice__hide {
  from {
    opacity: 1;
    height: auto;
  }
  to {
    opacity: 0;
    height: 0;
  }
}
@keyframes filters__choice__icon__open {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes filters__choice__icon__close {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.wapp--button {
  position: fixed;
  width: 90px;
  height: 90px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 60px;
  line-height: 90px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.wapp--button:hover {
  transform: scale(1.05);
  color: #FFF;
}
@media only screen and (max-width: 720px) {
  .wapp--button {
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    font-size: 40px;
    line-height: 60px;
  }
}

.reviews__list {
  position: relative;
}
.reviews__list .slick-arrow {
  padding: auto;
  margin: auto;
  z-index: 1;
  background-color: #000 !important;
  color: #000 !important;
  width: 50px;
  height: 50px;
}
.reviews__list .slick-prev {
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
  transform: translate(-100%, 0);
}
@media screen and (max-width: 720px) {
  .reviews__list .slick-prev {
    top: 95%;
    transform: translate(-35%, 0);
  }
}
.reviews__list .slick-prev:before {
  font-family: "Quicksand", sans-serif !important;
  content: "←";
  border-radius: 100%;
}
.reviews__list .slick-next {
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 50%;
  transform: translate(100%, 0);
}
@media screen and (max-width: 720px) {
  .reviews__list .slick-next {
    top: 95%;
    transform: translate(35%, 0);
  }
}
.reviews__list .slick-next:before {
  font-family: "Poppins", sans-serif !important;
  content: "→";
  border-radius: 100%;
}
.reviews__list .slick-track {
  display: flex !important;
}
.reviews__list .slick-slide {
  width: 100% !important;
  height: 100% !important;
}
.reviews__list .review {
  position: relative;
  width: 75%;
  height: 300px;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  margin: 15px;
}
@media screen and (max-width: 720px) {
  .reviews__list .review {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }
}
.reviews__list .review .image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.5s ease-in-out;
}
.reviews__list .review .logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 20px auto;
}
.reviews__list .review__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-wrap: wrap;
  overflow-y: auto;
}
.reviews__list .review__content .choice {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.reviews__list .review__content .choice img {
  width: 50%;
  height: 50%;
  margin: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@font-face {
  font-family: "Droids Sans";
  src: url("../assets/fonts/Droids Sans Italic.otf") format("opentype");
}
.disclaimer {
  font-family: "Droids Sans", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .disclaimer {
    padding: 20px;
    flex-direction: column;
  }
}
.disclaimer img {
  width: 300px;
  height: 150px;
  padding: 0 25px;
  margin: 20px 0;
  -o-object-fit: contain;
     object-fit: contain;
  mix-blend-mode: multiply;
  border-radius: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media screen and (max-width: 768px) {
  .disclaimer img {
    width: 100%;
  }
}
.disclaimer a {
  text-decoration: underline;
  color: #333;
}

* {
  margin: 0;
  padding: 0;
  -webkit-user-drag: none;
  box-sizing: border-box;
}

body {
  font-family: "Playfair Display", serif;
  background-color: white;
  font-weight: 300;
}

.layout {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 75px;
  padding: 0 0 75px 0;
}
@media only screen and (max-width: 720px) {
  .layout {
    padding-bottom: 20px;
  }
}

.image--loading {
  background: #f9f9f9;
  border-radius: 5px;
  transform: scale(1.025);
}

.lazyload {
  animation: show 0.5s forwards ease-in-out;
}

a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: inherit;
  text-decoration: inherit;
}

h1 {
  font-size: 20px !important;
  line-height: 30px;
  font-weight: lighter;
}

p {
  font-size: 14px !important;
  line-height: 18px;
  font-weight: lighter;
}

.title {
  font-family: "Playfair Display SC", serif !important;
  font-size: 45px;
  font-weight: 500;
  text-align: start;
}

.subtitle {
  font-family: "Playfair Display", serif !important;
  font-size: 24px;
  font-weight: 200;
  text-align: start;
}

textarea {
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border: none;
  border: 1px solid #9e9e9e;
  border-radius: 10px;
  background-color: white;
  font-size: 1.2rem;
  color: #5E5E5E;
  outline: none;
}

.password__group {
  position: relative;
}
.password__group .visibility {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  width: auto;
  border: none;
  background: transparent;
  color: #5E5E5E;
}

.slide_image {
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}

.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 900;
}

.icon {
  transform: translateY(25%) scale(0.75);
}

.w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.whitespace-break-spaces {
  white-space: pre-line !important;
}

.pointer {
  cursor: pointer;
}

.payments__icon {
  width: 200px;
  height: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}

.details {
  font-weight: initial !important;
}

.button {
  padding: 10px 20px;
  border: none;
  background-color: #FFB6B6;
  border-radius: 32px;
  color: #f9f9f9;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.button:hover {
  color: #f9f9f9;
  transform: translate(0, -2px);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.input__group {
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 25px;
}
.input__group label {
  width: 100%;
  font-size: 1rem;
  font-weight: lighter;
  color: #5E5E5E;
}
.input__group input {
  width: 100%;
  padding: 5px 15px;
  border: none;
  border: 1px solid #9e9e9e;
  border-radius: 20px;
  background-color: transparent !important;
  font-size: 1.2rem;
  color: #5E5E5E;
  outline: none;
}
@media only screen and (max-width: 720px) {
  .input__group {
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 25px;
  }
}

.password__group {
  position: relative;
}
.password__group .visibility {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  width: auto;
  border: none;
  background: transparent;
  color: #5E5E5E;
}

.text-pink {
  color: #FFB6B6 !important;
}

.Toastify__toast {
  border-radius: 15px;
  background-color: white;
  margin: 10px;
}
@media screen and (max-width: 720px) {
  .Toastify__toast-container--bottom-right {
    bottom: 2em;
    right: 1em;
  }
}/*# sourceMappingURL=index.css.map */