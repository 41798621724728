@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@import 'header';
@import 'slider';
@import 'cart';
@import 'checkout';
@import 'configurator';
@import 'loader';
@import 'gallery';
@import 'containers';
@import 'products';
@import 'product';
@import 'footer';
@import 'filters';
@import 'wapp';
@import 'reviews';
@import 'disclaimer';

* {
    margin: 0;
    padding: 0;
    -webkit-user-drag: none;
    box-sizing: border-box;
}

body {
    font-family: "Playfair Display", serif;
    background-color: white;
    font-weight: 300
}

.layout {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 75px;
    padding: 0 0 75px 0;

    @media only screen and (max-width: 720px) {
        padding-bottom: 20px;
    }
}

.image--loading {
    background: #f9f9f9;
    border-radius: 5px;
    transform: scale(1.025);
}

.lazyload {
    animation: show 0.5s forwards ease-in-out;
}

a {
    color: inherit;
    text-decoration: inherit;

    &:hover {
        color: inherit;
        text-decoration: inherit;
    }
}

h1 {
    font-size: 20px !important;
    line-height: 30px;
    font-weight: lighter;
}

p {
    font-size: 14px !important;
    line-height: 18px;
    font-weight: lighter;
}

.title {
    font-family: "Playfair Display SC", serif !important;
    font-size: 45px;
    font-weight: 500;
    text-align: start;
}

.subtitle {
    font-family: "Playfair Display", serif !important;
    font-size: 24px;
    font-weight: 200;
    text-align: start;
}

textarea {
    width: 100%;
    height: auto;
    padding: 5px 15px;
    border: none;
    border: 1px solid #9e9e9e;
    border-radius: 10px;
    background-color: white;
    font-size: 1.2rem;
    color: #5E5E5E;
    outline: none;
}

.password__group {
    position: relative;

    & .visibility {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        width: auto;
        border: none;
        background: transparent;
        color: #5E5E5E;
    }
}

.slide_image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.bold {
    font-weight: 700;
}

.bolder {
    font-weight: 900;
}

.icon {
    transform: translateY(25%) scale(0.75);
}

.w-fit {
    width: fit-content !important;
}

.h-fit {
    height: fit-content !important;
}

.whitespace-break-spaces {
    white-space: pre-line !important;
}

.pointer {
    cursor: pointer;
}

.payments__icon {
    width: 200px;
    height: 25px;
    object-fit: contain;
}

.details {
    font-weight: initial !important;
}

.button {
    padding: 10px 20px;
    border: none;
    background-color: #FFB6B6;
    border-radius: 32px;
    color: #f9f9f9;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;

    &:hover {
        color: #f9f9f9;
        transform: translate(0, -2px);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
}

.input__group {
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 25px;

    & label {
        width: 100%;
        font-size: 1rem;
        font-weight: lighter;
        color: #5E5E5E;
    }

    & input {
        width: 100%;
        padding: 5px 15px;
        border: none;
        border: 1px solid #9e9e9e;
        border-radius: 20px;
        background-color: transparent !important;
        font-size: 1.2rem;
        color: #5E5E5E;
        outline: none;
    }

    @media only screen and (max-width: 720px) {
        width: 300px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding: 25px;
    }
}

.password__group {
    position: relative;

    & .visibility {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0, -50%);
        width: auto;
        border: none;
        background: transparent;
        color: #5E5E5E;
    }
}

.text-pink {
    color: #FFB6B6 !important;
}


.Toastify__toast {
    border-radius: 15px;
    background-color: white;
    margin: 10px;
    
    @media screen and (max-width: 720px) {
        &-container--bottom-right {
          bottom: 2em;
          right: 1em;
        }
    }
  }