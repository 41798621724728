.cart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__product {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__preview {
            position: relative;
        }

        &__image {
            width: 80px;
            height: 100px;
            border-radius: 10px;
            object-fit: contain;
        }

        &__climage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            object-fit: contain;
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-size: 12px;
            padding: 0 5px;
        }
    }
}