.product__container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    
    @media screen and (max-width: 720px) {
        align-items: center;
        flex-direction: column;
    }

    & .bg-sec{
        background-color: #FF006B;
    }

    & .images {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 10px;

        @media screen and (max-width: 720px) {
            margin: 0 auto;
            flex-direction: column;
            justify-content: center;
        }

        & .preview {
            position: relative;
            width: 35vw;
            height: auto;

            @media screen and (max-width: 720px) {
                width: 90vw !important;
                height: 60vh !important;
            }

            & .slide {
                position: relative;
                width: 35vw;
                height: auto;
                object-fit: cover !important;

                @media screen and (max-width: 720px) {
                    width: 90vw !important;
                    height: 60vh !important;
                }
            }
        }

        & .image {
            position: relative;
            width: 100px;
            height: 100px;
            object-fit: cover !important;
        }
    }

    & .title {
        color: black !important;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
    }

    & .price {
        color: #FF006B !important;
        font-weight: 700;
        font-size: 34px;
    }

    & .label {
        font-weight: bold;
        font-size: 16px;
        color: #B9BBBF;
        text-transform: uppercase;
    }

    & .button {
        background-color: #000;
        width: 100%;
        border-radius: 35px;
        
        &--buy {
            // animation: button__buy 0.5s ease-in-out infinite alternate;
            background-color: #FF006B;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            height: 65px;
            width: 50%;
            font-weight: 600;

            &:hover{
                background-color: #FF006B !important;
            }
        }

        @media screen and (max-width: 720px) {
            margin: 0 0 !important;
            width: 100% !important;
            padding: 8px;
        }

        &:hover {
            background-color: #333;
        }
    }

    & .button--reverse {

        width: 50%;
        height: 65px;
        margin-left: 50px;

        background-color: #fff;
        color: #FF006B;
        border: 1px solid #FF006B;
        border-radius: 35px;
        padding: 8px;
        transition: 0.5s ease-in-out;
        font-weight: 600;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        
        & img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            filter: invert(17%) sepia(91%) saturate(6208%) hue-rotate(329deg) brightness(104%) contrast(109%);;
        }
        
        &:hover {
            transform: scale(1.1);
            border: 1px solid 333;

            @media screen and (max-width: 720px) {
                transform: scale(1.01);
            }
        }

        @media screen and (max-width: 720px) {
            width: 100%;
            margin-left: 0px;
        }
    }

    & .button--rev-active {
        width: 50%;
        height: 65px;
        margin-left: 50px;

        background-color: #FF006B;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 35px;
        padding: 8px;
        transition: 0.5s ease-in-out;
        font-weight: 600;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        
        & img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            filter: invert(1);
        }
        
        &:hover {
            transform: scale(1.2);

            @media screen and (max-width: 720px) {
                transform: scale(1.01);
            }
            
            & img {
                filter: invert(1);
            }
        }
        @media screen and (max-width: 720px) {
            width: 100%;
            margin-left: 0px;
        }
        
    }

    & .quantity {
        // border: 1px solid black;
        height: 65px;
        border-radius: 35px;
        padding: 4px 15px;
        font-size: 20px;
        color: #FF006B;
        border: 1px solid #FF006B;
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        user-select: none;

        @media screen and (max-width: 720px) {
            padding: 4px 10px;
            font-size: 12px;
        }

        & .button {
            border: none;
            background-color: transparent;
            font-size: 32px;
            line-height: 20px;
            cursor: pointer;
            color: #FF006B;
            padding: 0;
            outline: none;

            @media screen and (max-width: 720px) {
                font-size: 12px;
                line-height: 10px;
                padding: 0 !important;
            }
        }
    }

    & .sizes {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        user-select: none;

        & .active {
            background-color: #333 !important;
            color: white;
        }

        & .size {
            min-width: 40px !important;
            height: 40px !important;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 1px solid #999;
            border-radius: 50%;
            transition: 0.5s ease-in-out;
            margin: 4px;
            padding: 10px;

            &:hover {
                border: 1px solid #000;
            }

            & .value {
                align-items: center;
                text-align: center;
                line-height: 42px;
                border-radius: 50%;
                margin: auto;
            }
        }
    }

    & .colors {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;

        & .active {
            border: 2px solid black;
            border-radius: 50%;

            & .color {
                margin: 2px;
            }
        }

        & .color {
            width: 40px !important;
            height: 40px !important;
            background-color: white;
            border: 1px solid #999;
            border-radius: 50%;
            margin: 4px;
        }

        & .red {
            background-color: rgb(221, 42, 42);
        }

        & .blue {
            background-color: rgb(59, 160, 255);
        }

        & .green {
            background-color: rgb(59, 226, 59);
        }

        & .yellow {
            background-color: rgb(255, 237, 78);
        }

        & .orange {
            background-color: rgb(255, 166, 0);
        }

        & .purple {
            background-color: rgb(153, 32, 153);
        }

        & .black {
            background-color: black;
        }

        & .white {
            background-color: white;
        }

        & .grey {
            background-color: grey;
        }

        & .brown {
            background-color: rgb(102, 37, 17);
        }

        & .pink {
            background-color: rgb(255, 145, 163);
        }

        & .nude {
            background-color: #E3BC9A;
        }
    }
}

@keyframes button__buy {
    0% {
        transform: translate(-2px, 1px);
    }

    25% {
        transform: translate(-2px, 0px);
    }

    50% {
        transform: translate(0px, 0px);
    }

    75% {
        transform: translate(0px, 1px);
    }

    100% {
        transform: translate(2px, 1px);
    }
}

.dropdown_card{
    background-color: #FAFAFA;
    color: #000;

}